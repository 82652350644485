import usePrice from '@commerce/product/use-price'
import { Cart } from '@commerce/types'
import { getCheckoutId } from '@commerce/utils'
import SidebarLayout from '@components/common/SidebarLayout'
import { CartBig, Lock } from '@components/icons'
import { Button } from '@components/ui'
import { useUI } from '@components/ui/context'
import fetcher, { swrOptions } from '@lib/fetcher'
import cn from 'classnames'
import router from 'next/router'
import { FC } from 'react'
import useSWR from 'swr'
import CartItem from '../CartItem'
import s from './CartSidebarView.module.css'

const CartSidebarView: FC = () => {
  const { closeSidebar, setSidebarView } = useUI()
  const checkoutId = getCheckoutId()
  const {
    data,
    error: cartError,
    isValidating,
  } = useSWR(checkoutId ? '/api/cart' : null, fetcher, swrOptions)
  const cart: Cart.Cart | null = data?.cart
  const isEmpty = !cart || cart.lineItems.length === 0

  // const { price: subTotal } = usePrice(
  //   cart && {
  //     amount: Number(cart.subtotalPrice),
  //     currencyCode: cart.currency.code,
  //   }
  // )
  const { price: total } = usePrice(
    cart && {
      amount: Number(cart.totalPrice),
      currencyCode: cart.currency.code,
    }
  )

  const cartQty = data?.cart?.lineItems
    .map((item: any) => item.quantity)
    .reduce((x: any, y: any) => x + y, 0)
  const handleClose = () => closeSidebar()
  const goToCheckout = () => {
    router.push(`/checkout/${checkoutId}`)
    closeSidebar()
  }

  return (
    <SidebarLayout
      className={cn({
        [s.empty]: isEmpty,
      })}
      handleClose={handleClose}
      heading={isEmpty ? 'Your cart is empty' : 'Your cart'}
      subHeading={
        cart && cart?.lineItems.length > 0
          ? `${cartQty} ${cartQty === 1 ? 'item' : 'items'}`
          : undefined
      }
    >
      {cart && (
        <>
          {isEmpty ? (
            <div className="flex-1 px-4 flex flex-col justify-center items-center">
              <CartBig />
              <p className="body-2 px-10 pt-6 text-center">
                Your products will be displayed here once you add them to your
                cart
              </p>
            </div>
          ) : (
            <>
              <div className="p-5 border-t border-greyBg flex-1">
                <ul className={s.lineItemsList}>
                  {cart!.lineItems.map((item: any) => (
                    <CartItem
                      key={item.id}
                      item={item}
                      currencyCode={cart!.currency.code}
                      version={cart!.version}
                    />
                  ))}
                </ul>
              </div>

              <div className="flex-shrink-0 p-5 sticky z-20 bottom-0 w-full right-0 left-0 bg-accent-0">
                <div className="border-t checkout-button pt-3 border-greyBg">
                  <Button
                    Component="a"
                    width="100%"
                    style={{ height: '48px' }}
                    onClick={goToCheckout}
                  >
                    <div className="flex gap-2">
                      <Lock className="mt-1" />
                      <div className="label-regular text-white">
                        Checkout - {total}
                      </div>
                    </div>
                  </Button>

                  {/*
                // redirect to hosted checkout
                <Button href="/checkout" Component="a" width="100%">
                  Proceed to Checkout
                </Button> */}
                </div>
              </div>
              <style jsx global>
                {`
                  .checkout-button a {
                    text-decoration: none;
                  }
                `}
              </style>
            </>
          )}
        </>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
