import { useUser } from '@auth0/nextjs-auth0'
import { Customer } from '@commerce/schema'
import { Cross, User, UserHeavy } from '@components/icons'
import { Container } from '@components/ui'
import { goToLogin } from '@lib/auth0'
import fetcher, { swrOptions } from '@lib/fetcher'
import { FC } from 'react'
import useSWR from 'swr'
import MobileNavContainer from './MobileNavContainer'

interface Props {
  navItems?: []
  extraNavItems?: []
  handleCloseNav(): void
}

const MobileNav: FC<Props> = ({ navItems, extraNavItems, handleCloseNav }) => {
  const { user } = useUser()

  const { data: customerData } = useSWR(
    user ? '/api/customer' : null,
    fetcher,
    swrOptions
  )

  const customer: Customer = customerData?.customer
  const handleOpenProfile = () => {
    goToLogin()
    handleCloseNav()
  }
  return (
    <div className="w-full bg-white h-full">
      <div className="h-full w-full ">
        <div className="border-b border-greyBg py-4 ">
          <Container>
            <div className="flex items-center justify-between ">
              <h5 className="text-mobileh5 font-icomoon text-center w-full">
                Menu
              </h5>
              <button className="focus:outline-none" onClick={handleCloseNav}>
                <Cross />
              </button>
            </div>
          </Container>
        </div>
        <MobileNavContainer items={navItems} handleCloseNav={handleCloseNav} />
        <div className="border-b border-greyBg"></div>
        <Container>
          <button
            className="flex py-6 items-center no-underline"
            onClick={handleOpenProfile}
          >
            {customer?.firstName ? (
              <UserHeavy color="#000000" />
            ) : (
              <User color="#000000" />
            )}
            <div className="text-h7 text-secondary font-icomoon flex items-center pl-3 pt-1">
              {customer?.firstName
                ? `Hi ${customer?.firstName}`
                : ' My profile'}
            </div>
          </button>
        </Container>
        <div className="border-b border-greyBg"></div>
        <MobileNavContainer
          items={extraNavItems}
          handleCloseNav={handleCloseNav}
        />
      </div>
    </div>
  )
}

export default MobileNav
