import { useUser } from '@auth0/nextjs-auth0'
import { Customer } from '@commerce/schema'
import { getCheckoutId } from '@commerce/utils'
import { LocationBar } from '@components/common'
import { Cart, Hamburger, Logo, User, UserHeavy } from '@components/icons'
import { Container } from '@components/ui'
import { useUI } from '@components/ui/context'
import { goToLogin } from '@lib/auth0'
import fetcher, { swrOptions } from '@lib/fetcher'
import Link from 'next/link'
import { FC } from 'react'
import useSWR from 'swr'
import NavbarRoot from './NavbarRoot'

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const { user } = useUser()

  const { data: customerData } = useSWR(
    user ? '/api/customer' : null,
    fetcher,
    swrOptions
  )

  const customer: Customer = customerData?.customer

  const { toggleSidebar, closeSidebarIfPresent, openModal, setSidebarView } =
    useUI()
  //const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0
  const itemsCount = 0
  const handleLogin = () => goToLogin()

  const checkoutId = getCheckoutId()

  const {
    data,
    error: cartError,
    isValidating,
  } = useSWR(checkoutId ? '/api/cart' : null, fetcher, swrOptions)

  const cartQty = data?.cart?.lineItems
    .map((item: any) => item.quantity)
    .reduce((x: any, y: any) => x + y, 0)

  return (
    <NavbarRoot>
      <Container>
        <LocationBar />
      </Container>
      <div className="border-b border-lightGrey"></div>

      <Container>
        <div className="w-full py-2 text-12 flex justify-between md:py-3">
          <button
            type="button"
            onClick={() => {
              setSidebarView('MOBILEMENU_VIEW')
              toggleSidebar()
            }}
            className="focus:outline-none flex items-center lg:hidden"
          >
            <Hamburger />
          </button>

          <Link href="/" passHref>
            <a>
              <Logo />
            </a>
          </Link>

          <div className="flex items-center">
            <div className="hidden lg:block">
              {customer?.firstName ? (
                <Link href="/me" passHref>
                  <a className=" bg-white rounded-2xl flex h-8 items-center justify-center px-5 py-2">
                    <UserHeavy />
                    <div className="font-icomoon text-h7 pl-3 pt-1">
                      Hi {customer?.firstName}
                    </div>
                  </a>
                </Link>
              ) : (
                <a
                  onClick={handleLogin}
                  className="cursor-pointer"
                  // target="_blank"
                >
                  <User />
                </a>
              )}
            </div>

            <button
              className="pl-6 focus:outline-none relative"
              onClick={() => {
                setSidebarView('CART_VIEW')
                toggleSidebar()
              }}
            >
              <Cart />
              {cartQty > 0 && (
                <span className="block absolute cartQty h-4 w-4 bg-amber rounded-full text-body3 font-roboto text-white flx justify-center items-center">
                  {cartQty}
                </span>
              )}
            </button>
          </div>
        </div>

        <style jsx>
          {`
            .cartQty {
              top: -5px;
              right: -7px;
            }
          `}
        </style>
      </Container>
    </NavbarRoot>
  )
}

export default Navbar
