import { Container } from '@components/ui'
import { FC } from 'react'
import FooterLinks from './FooterLinks'
import FooterLinksMobile from './FooterLinksMobile'
import FooterRoot from './FooterRoot'

interface Props {
  className?: string
  children?: any
  pages?: []
}

const Footer: FC<Props> = ({ className, pages }) => {
  return (
    <footer>
      <div className="bg-royalBlue pt-8 pb-5.5 md:pt-10 md:pb-6">
        <Container>
          <div className="hidden lg:block">
            <FooterLinks pages={pages} />
          </div>
          <div className="block lg:hidden">
            <FooterLinksMobile pages={pages} />
          </div>
          <FooterRoot />
        </Container>
      </div>
    </footer>
  )
}

export default Footer
