import MobileNav from '@components/common/MobileNav'
import { useUI } from '@components/ui/context'
import { FC } from 'react'

interface MenuProps {
  navItems?: []
  extraNavItems?: []
}

const MenuSidebarView: FC<MenuProps> = ({ navItems, extraNavItems }) => {
  const { closeSidebar } = useUI()
  const handleClose = () => closeSidebar()

  return (
    <MobileNav
      navItems={navItems}
      handleCloseNav={handleClose}
      extraNavItems={extraNavItems}
    />
  )
}

export default MenuSidebarView
