import { Container } from '@components/ui'
import { FC } from 'react'
import MobileMegaNav from './MobileMegaNav'
import MobileNavItem from './MobileNavItem'

interface Props {
  items?: []
  handleCloseNav(): void
}

interface ItemProps {
  slice_type: string
  primary: {
    nav_title: string
    nav_link: string
    view_all_title?: string
    view_all_link?: string
  }
  items: []
}

const MobileNav: FC<Props> = ({ items, handleCloseNav }) => {
  return (
    <Container>
      <div className="pt-1.5">
        {items &&
          items.length &&
          items.map((item: ItemProps) => {
            switch (item.slice_type) {
              case 'nav_item':
                return (
                  <MobileNavItem
                    key={item.primary.nav_title}
                    title={item.primary.nav_title}
                    link={item.primary.nav_link}
                    closeNav={handleCloseNav}
                  />
                )

              case 'mega_nav_items':
                return (
                  <MobileMegaNav
                    key={item.primary.nav_title}
                    title={item.primary.nav_title}
                    link={item.primary.nav_link}
                    viewAllTitle={item.primary?.view_all_title}
                    viewAllLink={item.primary?.view_all_link}
                    items={item.items}
                    closeNav={handleCloseNav}
                  />
                )
              default:
                return null
            }
          })}
      </div>
    </Container>
  )
}

export default MobileNav
