const Facebook = ({ ...props }) => {
  return (
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.083 27.333c6.375-1.007 11.25-6.556 11.25-13.252C27.333 6.671 21.366.667 14 .667 6.634.667.667 6.67.667 14.08c0 6.695 4.875 12.245 11.25 13.252V17.96H8.53V14.08h3.387v-2.955c0-3.362 1.989-5.219 5.036-5.219 1.459 0 2.985.262 2.985.262v3.3h-1.682c-1.656 0-2.173 1.034-2.173 2.094v2.518h3.698l-.591 3.878h-3.107v9.374Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Facebook
