const Location = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.55c1.183 0 2.143-1.007 2.143-2.25S7.183 4.05 6 4.05c-1.183 0-2.143 1.007-2.143 2.25S4.817 8.55 6 8.55ZM6 0C2.683 0 0 2.817 0 6.3 0 11.025 6 18 6 18s6-6.975 6-11.7C12 2.817 9.317 0 6 0ZM1.714 6.3c0-2.484 1.92-4.5 4.286-4.5 2.366 0 4.286 2.016 4.286 4.5 0 2.592-2.469 6.471-4.286 8.892C4.217 12.789 1.714 8.865 1.714 6.3Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Location
