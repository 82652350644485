const CartBig = ({ ...props }) => {
  return (
    <svg
      width={48}
      height={44}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46 6H10.772l-.694-3.702A2 2 0 0 0 8.113.667H1a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h6.56l5.827 31.08a5.333 5.333 0 1 0 8.564.921H34.05a5.333 5.333 0 1 0 8.336-1.156 2.001 2.001 0 0 0-1.941-1.511h-24.67l-1-5.334h27.614a2 2 0 0 0 1.958-1.592l3.611-17.333A2 2 0 0 0 46 6Zm-7.333 29.333A2.67 2.67 0 0 1 41.333 38a2.67 2.67 0 0 1-2.666 2.667A2.67 2.67 0 0 1 36 38a2.67 2.67 0 0 1 2.667-2.667Zm-21.334 0A2.67 2.67 0 0 1 20 38a2.67 2.67 0 0 1-2.667 2.667A2.67 2.67 0 0 1 14.667 38a2.67 2.67 0 0 1 2.666-2.667Zm24.513-10.666H14.273l-3-16H45.18l-3.334 16ZM22.666 17v-.667a1 1 0 0 1 1-1h3v-3a1 1 0 0 1 1-1h.667a1 1 0 0 1 1 1v3h3a1 1 0 0 1 1 1V17a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1h-.666a1 1 0 0 1-1-1v-3h-3a1 1 0 0 1-1-1Z"
        fill="#242424"
      />
    </svg>
  )
}

export default CartBig
