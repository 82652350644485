import { Close } from '@components/icons'
import { Container } from '@components/ui'
import { useAnnouncementCookies } from '@lib/hooks/useAnnouncementCookies'
import { customLink } from '@lib/prismic'
import Link from 'next/link'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'

interface Props {
  bannerData: {
    background_colour: string
    cookie_name: string
    desktop_texts: RichTextBlock[]
    link: { url: string }
    mobile_texts: RichTextBlock[]
    text_colour: string
    title: string
    show_close_button: boolean
  }
}

const AnnouncementBar: FC<Props> = ({ bannerData }) => {
  const cookieName = bannerData?.cookie_name

  const showClose = bannerData?.show_close_button

  const { acceptedCookies, hideAnnouncement } = useAnnouncementCookies({
    cookieName,
  })

  const desktopContents =
    bannerData?.desktop_texts && RichText.asText(bannerData?.desktop_texts)
  const mobileContents =
    bannerData?.mobile_texts && RichText.asText(bannerData?.mobile_texts)

  const blockLink = bannerData?.link?.url

  return (
    <>
      {desktopContents && cookieName && !acceptedCookies ? (
        <div className="w-full announcementBar py-2 relative">
          <Container>
            <div className="flex items-center relative">
              <Link href={blockLink || '/'} passHref>
                <div className="w-full cursor-pointer flex justify-center items-center">
                  <div className="hidden bannerTexts font-body3 md:block">
                    <RichText
                      render={bannerData?.desktop_texts}
                      serializeHyperlink={customLink}
                    />
                  </div>
                  <div className="mobileBanner block font-body3 text-center md:hidden">
                    {mobileContents ? (
                      <RichText
                        render={bannerData?.mobile_texts}
                        serializeHyperlink={customLink}
                      />
                    ) : (
                      <RichText
                        render={bannerData?.desktop_texts}
                        serializeHyperlink={customLink}
                      />
                    )}
                  </div>
                </div>
              </Link>
              {showClose && (
                <div
                  className="absolute right-0 top-0 h-full cursor-pointer flex items-center"
                  onClick={hideAnnouncement}
                >
                  <Close />
                </div>
              )}
            </div>
          </Container>
        </div>
      ) : null}

      <style jsx>
        {`
          .announcementBar {
            background: ${bannerData?.background_colour};
          }

          .mobileBanner {
            background: ${bannerData?.background_colour};
            color: ${bannerData?.text_colour};
          }
          .bannerTexts {
            color: ${bannerData?.text_colour};
          }
        `}
      </style>
      <style jsx global>
        {`
          .announcementBar p,
          .announcementBar a {
            font-size: 0.75rem;
            line-height: 1.33;
            font-family: RobotoRegular;
            color: #f5f5f5;
          }
        `}
      </style>
    </>
  )
}

export default AnnouncementBar
