import { ImageField } from '@prismicio/types'
import Image from 'next/image'
import Link from 'next/link'
import FooterLocation from './FooterLocation'

interface footerLink {
  primary: {
    column_title: string
  }
  items: { link_title: string; link: string; social_icon: ImageField }[]
}
interface footerLinkProps {
  pages?: footerLink[]
}

const FooterLinks = ({ pages }: footerLinkProps) => {
  return (
    <div className=" flex justify-between">
      {pages &&
        pages.map((item, key) => {
          const navItem = item?.items
          const itemTitle = item?.primary?.column_title
          return (
            <div key={key} className="">
              <ul>
                <li className="text-white text-h6 font-icomoon py-2">
                  {itemTitle}
                </li>
                {navItem &&
                  navItem.map((item, key) => (
                    <li key={key} className="py-2">
                      {item?.link === '/me' ? (
                        <a
                          href={item?.link}
                          className="flex text-body2 font-roboto text-lightGrey no-underline hover:text-white"
                        >
                          {item?.link_title}
                        </a>
                      ) : (
                        <Link href={item?.link || '/'} passHref>
                          <a
                            href={item?.link}
                            className="flex text-body2 font-roboto text-lightGrey no-underline hover:text-white"
                          >
                            {item?.social_icon?.url && (
                              <div className="pr-2.5">
                                <Image
                                  src={item?.social_icon?.url}
                                  alt="Picture of the author"
                                  width={20}
                                  height={20}
                                />
                              </div>
                            )}
                            {item?.link_title}
                          </a>
                        </Link>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          )
        })}

      <FooterLocation />
    </div>
  )
}

export default FooterLinks
