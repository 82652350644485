import { Location, Phone } from '@components/icons'
import { Button, useUI } from '@components/ui'
import { useEffect, useState } from 'react'

const FooterLocation = () => {
  const {
    postcode: code,
    suburb,
    setModalView,
    openModal,
    setSidebarView,
    toggleSidebar,
  } = useUI()
  const [postcode, setPostcode] = useState('')
  useEffect(() => {
    if (code) {
      setPostcode(code)
    }
  }, [code])
  const handleLocationSet = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    const width = window.innerWidth
    if (width && width < 1024) {
      setSidebarView('LOCATION_VIEW')
      toggleSidebar()
    } else {
      setModalView('LOCATION_VIEW')
      openModal()
    }
  }
  return (
    <ul>
      <li className="text-white text-h6 font-icomoon py-2">
        {postcode ? 'Your Location' : 'Set your location'}
      </li>
      {postcode && (
        <li className="mb-6 flex">
          <div className="flex gap-2.5 pr-2">
            <Location />
            <div className="body-3 text-lightGrey">
              Delivery to | {postcode} {suburb}
            </div>
          </div>

          <span
            className="block body-3 text-lightGrey underline md:hidden"
            onClick={handleLocationSet}
          >
            Edit address
          </span>
        </li>
      )}

      <li className="hidden pb-15 md:block">
        <Button variant="whitePrimary" onClick={handleLocationSet}>
          {postcode ? 'Edit location' : 'Set my location'}
        </Button>
      </li>

      <li className="pb-9">
        <div className="text-h7 font-icomoon text-white pb-5">
          General inquiries
        </div>
        <a href="tel:131339" className="flex no-underline">
          <div className="pr-2 flex items-center">
            <Phone fill="#fff" />
          </div>
          <span className="flex items-center text-body3 font-roboto text-white ">
            Call us: 131339
          </span>
        </a>
      </li>
      <li>
        <div className="text-h7 font-icomoon text-white pb-5">
          Emergency Spills Hotline
        </div>
        <a href="tel:1800 774 557" className="flex no-underline">
          <div className="pr-2 flex items-center">
            <Phone fill="#fff" />
          </div>
          <span className=" flex items-center text-body3 font-roboto text-white ">
            Call us: 1800 774 557
          </span>
        </a>
      </li>
    </ul>
  )
}

export default FooterLocation
