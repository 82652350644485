import { ArrowDown, ArrowUp } from '@components/icons'
import cn from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import FooterLocation from './FooterLocation'

interface footerLink {
  primary: {
    column_title: string
  }
  items: { link_title: string; link: string }[]
}
interface footerLinkProps {
  pages?: footerLink[]
}

const FooterLinksMobile = ({ pages }: footerLinkProps) => {
  const [expandedIndex, setExpandedIndex] = useState(-1)
  return (
    <div className="w-full">
      <FooterLocation />
      <div className="flex justify-between w-full pt-7">
        <div className="w-full">
          {pages &&
            pages.map((column, index) => {
              const setExpanded = () => {
                if (expandedIndex === index) {
                  setExpandedIndex(-1)
                } else {
                  setExpandedIndex(index)
                }
              }
              return (
                <div key={column?.primary?.column_title}>
                  <div
                    className={cn(
                      'laptop:hidden w-full  focus:outline-none flex justify-between',
                      {
                        'border-b border-white':
                          expandedIndex !== index && index !== pages.length - 1,
                        'border-t border-white': index === 0,
                      }
                    )}
                    onClick={setExpanded}
                  >
                    {column?.primary?.column_title &&
                      column?.primary?.column_title !== 'Social' && (
                        <div className="text-h7 font-icomoon text-lightGrey py-4">
                          {column?.primary?.column_title}
                        </div>
                      )}

                    {index !== pages.length - 1 && expandedIndex !== index && (
                      <div className="flex items-center">
                        <ArrowDown stroke="#fff" />
                      </div>
                    )}
                    {index !== pages.length - 1 && expandedIndex === index && (
                      <div className="flex items-center">
                        <ArrowUp stroke="#fff" />
                      </div>
                    )}
                  </div>
                  <div
                    className={cn('', {
                      'hidden laptop:block':
                        index !== pages.length - 1 && expandedIndex !== index,
                      'border-b border-white laptop:border-none':
                        expandedIndex === index && index !== pages.length - 1,
                    })}
                  >
                    {column?.items && index !== pages.length - 1 && (
                      <ul className="">
                        {column.items.map((item, index) => (
                          <li key={item?.link_title} className="">
                            {item?.link_title && (
                              <Link href={item.link || '/'}>
                                <a
                                  className={cn(
                                    'block no-underline text-h7 font-icomoon text-lightGrey hover:text-lightGrey',
                                    {
                                      'pt-2': index !== 0,
                                    },
                                    {
                                      'pb-2': index !== column.items.length - 1,
                                    },
                                    {
                                      'pb-4': index === column.items.length - 1,
                                    }
                                  )}
                                >
                                  {item.link_title}
                                </a>
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default FooterLinksMobile
