import { ChevronRight } from '@components/icons'
import { RTImageNode } from '@prismicio/types'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface MobileMegaNavItemProps {
  title: any | string
  link: string
  megaNavIcon?: RTImageNode
  closeNav(): void
}

const MobileMegaNavItem: FC<MobileMegaNavItemProps> = ({
  title,
  link,
  megaNavIcon,
  closeNav,
}) => {
  const router = useRouter()

  const handleClick = () => {
    if (link) {
      closeNav()
      router.push(link)
    }
  }

  var re = /\([^\)]+\)/g
  const quantityNumber = title && title?.match(re)?.[0]
  var sliceStart = title && title?.indexOf('(')
  const navTitle = title && title?.substring(0, sliceStart)

  if (!title) return null

  return (
    <div className="flex justify-between py-3.5" onClick={handleClick}>
      <div className="flex items-center">
        {megaNavIcon?.url && (
          <div className="pr-4.5">
            <Image
              src={megaNavIcon?.url}
              alt="nav icon"
              width={20}
              height={20}
            />
          </div>
        )}

        <div className="text-h7 font-icomoon text-charcoal cursor-pointer">
          {navTitle || title}
          {quantityNumber && (
            <span className="text-midGrey">{quantityNumber}</span>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <ChevronRight />
      </div>
    </div>
  )
}

export default MobileMegaNavItem
