/* eslint-disable @next/next/no-html-link-for-pages */
import { Location, Phone, Support } from '@components/icons'
import { useUI } from '@components/ui'
import { FC, useEffect, useState } from 'react'

interface Props {}

const LocationBar: FC<Props> = () => {
  const {
    setModalView,
    closeModal,
    openModal,
    setSidebarView,
    toggleSidebar,
    postcode: code,
    suburb,
  } = useUI()

  const [postcode, setPostcode] = useState('')

  useEffect(() => {
    if (code) {
      setPostcode(code)
    }
  }, [code])

  return (
    <div className="bg-royalBlue">
      <div className="py-2 flex justify-between items-center">
        <div className=" text-lightGrey flex items-center">
          <Location />
          <div className="font-roboto pl-2.5 text-body3">
            {postcode ? (
              <span>
                Delivery to | {postcode} {suburb} &nbsp;&nbsp;
              </span>
            ) : (
              <span>Enter your &nbsp;</span>
            )}
            <span
              className="hidden lg:inline underline cursor-pointer"
              onClick={() => {
                setModalView('LOCATION_VIEW')
                openModal()
              }}
            >
              {postcode ? <span>Edit address</span> : <span>postcode</span>}
            </span>
            <span
              className="lg:hidden underline cursor-pointer"
              onClick={() => {
                setSidebarView('LOCATION_VIEW')
                toggleSidebar()
              }}
            >
              {postcode ? <span>Edit address</span> : <span>postcode</span>}
            </span>
            {!postcode && <span>&nbsp;for delivery options</span>}
          </div>
        </div>
        <div className="hidden md:flex">
          <div className="mr-7">
            <a
              href="mailto:workshop@cleanaway.com.au"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-body3 font-roboto gap-2 text-lightGrey no-underline hover:text-lightGrey"
            >
              <Support />
              Support
            </a>
          </div>
          <div className="flex items-center">
            <Phone fill="#fff" />
            <a
              href="tel:131339"
              className="text-body3 font-roboto pl-2 text-lightGrey no-underline hover:text-lightGrey"
            >
              Call us: 131339
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationBar
