const ArrowDown = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1 1 5 5 5-5"
        stroke={props.stroke || '#5A5A5A'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowDown
