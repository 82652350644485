const Close = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 1.808 10.193.75 6 4.942 1.808.75.75 1.808 4.942 6 .75 10.193l1.058 1.057L6 7.058l4.193 4.192 1.057-1.057L7.058 6l4.192-4.192Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Close
