const Support = ({ ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.2 12.176C19.2 7.784 15.792 4.8 12 4.8c-3.752 0-7.2 2.92-7.2 7.424-.48.272-.8.784-.8 1.376v1.6c0 .88.72 1.6 1.6 1.6h.8v-4.88c0-3.096 2.504-5.6 5.6-5.6 3.096 0 5.6 2.504 5.6 5.6v5.68h-6.4v1.6h6.4c.88 0 1.6-.72 1.6-1.6v-.976c.472-.248.8-.736.8-1.312v-1.84c0-.56-.328-1.048-.8-1.296Z"
        fill="#fff"
      />
      <path
        d="M9.6 13.6a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM14.4 13.6a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Z"
        fill="#fff"
      />
      <path
        d="M16.8 11.224A4.833 4.833 0 0 0 12.04 7.2c-2.424 0-5.032 2.008-4.824 5.16a6.46 6.46 0 0 0 3.888-4.712 6.43 6.43 0 0 0 5.696 3.576Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Support
