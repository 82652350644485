/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

interface Props {
  cookieName: string
}

export const useAnnouncementCookies = ({ cookieName }: any) => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setAcceptedCookies(false)
    }
  })

  const hideAnnouncement = () => {
    setAcceptedCookies(true)
    Cookies.set(cookieName, 'true', { expires: 365 })
  }

  return {
    acceptedCookies,
    hideAnnouncement,
  }
}
