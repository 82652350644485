import usePrice from '@commerce/product/use-price'
import type { LineItem } from '@commerce/types/cart'
import { useUI } from '@components/ui/context'
// import useUpdateItem from '/cart/use-update-item'
// import useRemoveItem from '/cart/use-remove-item'
import Quantity from '@components/ui/Quantity'
import commerceConfig from '@config/commerce.json'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { ChangeEvent, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import s from './CartItem.module.css'

const placeholderImg = '/product-img-placeholder.svg'
const { productOptionsPrefixCT } = commerceConfig

const CartItem = ({
  item,
  variant = 'default',
  currencyCode,
  version,
  ...rest
}: {
  variant?: 'default' | 'display'
  item: LineItem
  currencyCode: string
  version: number
}) => {
  const { closeSidebarIfPresent } = useUI()
  const { mutate } = useSWRConfig()
  const [removing, setRemoving] = useState(false)
  const [quantity, setQuantity] = useState<number>(item.quantity)
  // TODO: build remove items
  const removeItem = async (item: LineItem) => {
    const { data } = await fetch('/api/cart', {
      method: 'DELETE',
      body: JSON.stringify({
        items: [{ lineItemId: item.id, quantity: item.quantity }],
        version,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw 'Remove line item failed.'
        }
        return response.json()
      })
      .catch((error) => {
        // TODO: report error to bug tracker
        console.log('add to cart error', error)
      })
  }
  // TODO: build udpate item
  const updateItem = async (quantity: number) => {
    const { data } = await fetch('/api/cart', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [{ lineItemId: item.id, quantity }],
        version,
        type: 'UpdateItem',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw 'Remove line item failed.'
        }
        return response.json()
      })
      .catch((error) => {
        // TODO: report error to bug tracker
        console.log('add to cart error', error)
      })
  }

  const { price } = usePrice({
    amount: item.totalPrice.value,
    // baseAmount: item.variant.listPrice * item.quantity,
    currencyCode,
  })

  const handleChange = async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(value))

    try {
      setRemoving(true)
      await updateItem(Number(value))
      mutate('/api/cart')
      setRemoving(false)
    } catch (error) {
      setRemoving(false)
    }
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    setQuantity(val)
    try {
      setRemoving(true)
      await updateItem(Number(val))
      mutate('/api/cart')
      setRemoving(false)
    } catch (error) {
      setRemoving(false)
    }
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      mutate('/api/cart')
    } catch (error) {
      setRemoving(false)
    }
  }

  // TODO: Add a type for this
  const options = (item as any).options

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  return (
    <>
      <li
        className={cn(s.root, {
          'opacity-50 pointer-events-none': removing,
        })}
        {...rest}
      >
        <div className="flex gap-3 lg:gap-0 w-full">
          <div className="relative w-1/5">
            <Link href={`/product/${item.path}`}>
              <a>
                <Image
                  onClick={() => closeSidebarIfPresent()}
                  // className={s.productImage}
                  width={72}
                  height={72}
                  src={item.variant.image?.url || placeholderImg}
                  alt={item.variant.image?.altText || 'Product Image'}
                  unoptimized
                  layout="intrinsic"
                />
              </a>
            </Link>
          </div>
          <div className="w-4/5">
            <div className="cart-item body-3 text-primary">
              <Link href={`/product/${item.path}`}>
                <a>
                  <span onClick={() => closeSidebarIfPresent()}>
                    {item.name}
                  </span>
                </a>
              </Link>
            </div>
            <div className="mt-3 flex justify-between items-center">
              {variant === 'default' && (
                <Quantity
                  value={quantity}
                  handleRemove={handleRemove}
                  handleChange={handleChange}
                  increase={() => increaseQuantity(1)}
                  decrease={() => increaseQuantity(-1)}
                />
              )}
              <div className="body-bold-3 text-primary">{price}</div>
            </div>
          </div>
          {/* <div className="flex-1 flex flex-col text-base">
            {options && options.length > 0 && (
              <div className="flex items-center pb-1">
                {options.map((option: ProductOption, i: number) => {
                  const name = option.displayName.replace(
                    productOptionsPrefixCT,
                    ''
                  )

                  return (
                    <div
                      key={`${item.id}-${name}`}
                      className="text-sm font-semibold text-accent-7 inline-flex items-center justify-center"
                    >
                      {name}

                      <span className="mx-2 rounded-full bg-transparent border h-5 p-1 text-accent-9 inline-flex items-center justify-center overflow-hidden">
                        {option?.values?.[0]?.label}
                      </span>

                      {i === options.length - 1 ? (
                        ''
                      ) : (
                        <span className="mr-3" />
                      )}
                    </div>
                  )
                })}
              </div>
            )}
            {variant === 'display' && (
              <div className="text-sm tracking-wider">{quantity}x</div>
            )}
          </div> */}
        </div>
      </li>
      <style jsx global>
        {`
          .cart-item a {
            text-decoration: none;
          }
          .cart-item-image-container {
            height: 72px;
            width: 72px;
          }
        `}
      </style>
    </>
  )
}

export default CartItem
