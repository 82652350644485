import { ArrowDown, ArrowUp } from '@components/icons'
import { Item } from '@lib/types/MainNavigation'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, Fragment, useEffect, useState } from 'react'
import MegaNavigation from './MegaNavigation'

interface Props {
  children?: any
  navItems?: []
}

const Navigation: FC<Props> = ({ navItems }) => {
  const router = useRouter()
  const [topMenuHoverIndex, setTopMenuHoverIndex] = useState(-1)

  useEffect(() => {
    const handleRouteChange = () => setTopMenuHoverIndex(-1)
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  if (!navItems) return null

  return (
    <div className="border-none lg:border-b flex flex-col relative bg-lightestGrey border-b border-grey">
      <div className="wrapper hidden flex-row justify-start items-center h-14 lg:flex">
        <ul className="flex items-center">
          {navItems &&
            navItems.map((item: Item, index: number) => {
              const sliceType = item.slice_type
              const handleHover = () => setTopMenuHoverIndex(index)
              const handleOut = () => setTopMenuHoverIndex(-1)
              const megNavItems: any = item.items

              return (
                <Fragment key={index}>
                  {item.slice_type === 'nav_item' && (
                    <li className="py-5">
                      <Link href={item.primary?.nav_link || '/'}>
                        <a className="flex no-underline pr-5">
                          <span className="text-h7 font-icomoon text-charcoal hover:text-primary">
                            {item?.primary?.nav_title}
                          </span>
                        </a>
                      </Link>
                    </li>
                  )}
                  {item.slice_type === 'mega_nav_items' && (
                    <li
                      className="py-5"
                      onMouseOver={handleHover}
                      onMouseOut={handleOut}
                      onClick={handleHover}
                    >
                      <Link href={item.primary?.nav_link || '/'}>
                        <a className="flex no-underline pr-5">
                          <span
                            className={cn('text-h7 font-icomoon', {
                              'text-charcoal': topMenuHoverIndex !== index,
                              'text-primary': topMenuHoverIndex === index,
                            })}
                          >
                            {item?.primary?.nav_title}
                          </span>
                          <div className="pl-3 pt-1">
                            {sliceType === 'mega_nav_items' &&
                            megNavItems &&
                            topMenuHoverIndex === index ? (
                              <ArrowUp />
                            ) : (
                              <ArrowDown />
                            )}
                          </div>
                        </a>
                      </Link>
                      {sliceType === 'mega_nav_items' &&
                        megNavItems?.length > 0 && (
                          <MegaNavigation
                            parent={item}
                            topMenuCurrentIndex={topMenuHoverIndex}
                            currentIndex={index}
                          />
                        )}
                    </li>
                  )}
                </Fragment>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default Navigation
