import { ChevronLeft, ChevronRight } from '@components/icons'
import { Container } from '@components/ui'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import MobileMegaNavItem from './MobileMegaNavItem'

interface MobileMegaNavProps {
  title: string
  link: string
  items: []
  closeNav(): void
  viewAllTitle?: string
  viewAllLink?: string
}

const MobileMegaNav: FC<MobileMegaNavProps> = ({
  title,
  items,
  closeNav,
  viewAllTitle,
  viewAllLink,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const router = useRouter()

  const handleCloseSubNav = () => setOpenSubMenu(false)
  const handleOpenSubNav = () => setOpenSubMenu(true)

  const handleViewAllClick = () => {
    if (viewAllLink) {
      closeNav()
      router.push(viewAllLink)
    }
  }

  if (!title) {
    return null
  }

  return (
    <>
      <div onClick={handleOpenSubNav}>
        <div className="flex justify-between  py-3.5">
          <div className="text-h7 font-icomoon text-charcoal cursor-pointer">
            {title}
          </div>
          <ChevronRight />
        </div>
      </div>

      <div
        className={cn(
          'fixed h-full w-full md:w-screen md:max-w-md z-10 inset-0 bg-white transform transition-transform duration-300 subMenus box-border outline-none',
          { subMenusOpen: openSubMenu }
        )}
      >
        <div className="border-b border-greyBg py-4 ">
          <Container>
            <div className="flex items-center relative">
              <button
                onClick={handleCloseSubNav}
                className="absolute left-0 p-2"
              >
                <ChevronLeft />
              </button>
              <h5 className="text-mobileh5 font-icomoon text-center w-full">
                {title}
              </h5>
            </div>
          </Container>
        </div>

        {viewAllTitle && (
          <div className="border-b border-greyBg py-4">
            <Container>
              <button
                className="w-full flex items-center justify-between"
                onClick={handleViewAllClick}
              >
                <div className="text-h7 font-icomoon text-center text-charcoal">
                  {viewAllTitle}
                </div>
                <ChevronRight />
              </button>
            </Container>
          </div>
        )}

        <Container className="pt-2.5">
          {items &&
            items.length &&
            items?.map((item: any) => {
              if (!item.is_on_top) {
                return (
                  <MobileMegaNavItem
                    key={item.mega_nav_title}
                    title={item.mega_nav_title}
                    link={item.mega_nav_link}
                    megaNavIcon={item.mega_nav_icon}
                    closeNav={closeNav}
                  />
                )
              }
            })}
        </Container>
        <div className="border-b border-greyBg"></div>
      </div>

      <style jsx>
        {`
          .subMenus {
            --tw-translate-x: -100%;
          }
          .subMenusOpen {
            --tw-translate-x: 0;
          }
        `}
      </style>
    </>
  )
}

export default MobileMegaNav
