import { CHECKOUT_ID_COOKIE_NAME, COOKIE_EXPIRES } from '@commerce/const'
import Cookies from 'js-cookie'

const getCheckoutId = () => {
  return Cookies.get(CHECKOUT_ID_COOKIE_NAME)
}

export const setCheckoutId = (checkoutId: string) => {
  if (!checkoutId) return ''

  const options = { expires: COOKIE_EXPIRES }
  Cookies.set(CHECKOUT_ID_COOKIE_NAME, checkoutId, options)
}

export default getCheckoutId
