import { NavArrowRight } from '@components/icons'
import { Item, megNavItem } from '@lib/types/MainNavigation'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'

interface Props {
  children?: any
  parent: Item
  topMenuCurrentIndex: number
  currentIndex: number
}

const MegaNavigation: FC<Props> = ({
  parent,
  topMenuCurrentIndex,
  currentIndex,
}) => {
  const currentHover = currentIndex === topMenuCurrentIndex
  if (!parent) return null

  return (
    <div
      className={cn(
        `meg_nav_container absolute  w-full justify-between bg-white`,
        {
          currentHover,
        }
      )}
    >
      <div className="wrapper grid grid-cols-12">
        {parent?.primary?.view_all_title && (
          <Link href={parent?.primary?.view_all_link || '/'} passHref>
            <a className=" font-roboto text-body text-charcoal  whitespace-nowrap  hover:text-primary font-bold flex items-center no-underline">
              <span className="pr-2">{parent?.primary?.view_all_title}</span>
              <NavArrowRight />
            </a>
          </Link>
        )}

        <ul className="col-span-10 desktop:col-span-12 flex justify-center gap-x-6">
          {parent &&
            parent.items &&
            parent.items.map((item: megNavItem, index: number) => {
              return (
                <li key={index} className="py-3.5 h-full">
                  <Link href={item?.mega_nav_link || '/'} passHref>
                    <button className="text-body3 font-roboto text-charcoal block max-w-30 focus:outline-none">
                      {item?.mega_nav_icon?.url && (
                        <div className="flex justify-center pb-2.5 h-15">
                          <Image
                            src={item?.mega_nav_icon?.url}
                            alt="nav icon"
                            width={item?.mega_nav_icon?.dimensions?.width}
                            height={item?.mega_nav_icon?.dimensions?.height}
                          />
                        </div>
                      )}
                      <span className="block text-body3 font-roboto text-center text-charcoal  hover:text-primary">
                        {item?.mega_nav_title}
                      </span>
                    </button>
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>

      <style jsx>
        {`
          .meg_nav_container {
            visibility: hidden;
            left: 0;
            top: 56px;
            z-index: 50;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
          .currentHover {
            visibility: visible;
            opacity: 1;
          }
        `}
      </style>
    </div>
  )
}

export default MegaNavigation
