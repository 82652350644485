import * as prismic from '@prismicio/client'
import { PrismicDocument } from '@prismicio/types'
import Link from 'next/link'

export interface prismicPreviewData {
  preview: boolean
  previewData: any
  params: any
  locale?: string
  locales?: string[]
}

export const linkResolver = (doc: PrismicDocument | any) => {
  if (!doc || !doc?.uid) return '/'

  if (!doc || !doc?.uid || doc?.uid === 'homepage') return `/${doc.lang}`

  if (doc.type === 'page') {
    return `/pages/${doc.uid.replace('page-', '')}`
  }

  if (doc.type === 'general') {
    return `/${doc.uid.split('.').join('/')}`
  }

  return '/'
}

// Helper function to convert Prismic Rich Text links to Next/Link components
export const customLink = (type: string, element: any, content: any) => {
  const url = element?.data?.url
  const href = url.startsWith('https:///')
    ? url.replace('https:///', '/')
    : linkResolver(element.data)
  return (
    <Link key={`${element.type}-${element.start}`} href={url} passHref>
      <a>{content}</a>
    </Link>
  )
}

// Client method to query documents from the Prismic repo
const endpoint = prismic.getEndpoint(
  process.env.NEXT_PUBLIC_PRISMIC_REPOSITORY || ''
)
export const client = () => prismic.createClient(endpoint)

export const findCategoryIds = (sliceList: any) => {
  const categoryIds = sliceList
    ?.filter((slice: any) => slice.slice_type === 'product_list')
    .map((slice: any) => slice?.primary?.category_id)

  return categoryIds || []
}
