import { getCheckoutId, setCheckoutId } from '@commerce/utils'
import CartSidebarView from '@components/cart/CartSidebarView'
import { AnnouncementBar, Footer, Navbar, Navigation } from '@components/common'
import { Button, LoadingDots, Sidebar } from '@components/ui'
import { useUI } from '@components/ui/context'
import fetcher, { swrOptions } from '@lib/fetcher'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { RichTextBlock } from 'prismic-reactjs'
import React, { FC, useCallback, useEffect } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import MenuSidebarView from '../UserNav/MenuSidebarView'
import s from './Layout.module.css'

const Loading = () => (
  <div className="w-80 h-80 flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
)

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
  loading: Loading,
})

const Modal = dynamic(() => import('@components/ui/Modal'), {
  loading: Loading,
  ssr: false,
})

const LocationView = dynamic(() => import('@components/location'), {
  loading: Loading,
})

const exitPreviewMode = () => (location.href = '/api/exit-preview')

interface Props {
  pageProps: {
    preview: boolean
    announcementBar: {
      background_colour: string
      cookie_name: string
      desktop_texts: RichTextBlock[]
      link: { url: string }
      mobile_texts: RichTextBlock[]
      text_colour: string
      title: string
      show_close_button: boolean
    }
    navigationData?: {
      body: []
      body1: []
    }
    footerData?: {
      body: []
    }
  }
}

const ModalView: FC<{ modalView: string; closeModal(): any }> = ({
  modalView,
  closeModal,
}) => {
  return (
    <Modal onClose={closeModal}>
      {modalView === 'LOCATION_VIEW' && <LocationView />}
    </Modal>
  )
}

const ModalUI: FC = () => {
  const { displayModal, closeModal, modalView } = useUI()
  return displayModal ? (
    <ModalView modalView={modalView} closeModal={closeModal} />
  ) : null
}

const SidebarView: FC<{
  sidebarView: string
  closeSidebar(): any
  navItems?: []
  extraNavItems?: []
}> = ({ sidebarView, closeSidebar, navItems, extraNavItems }) => {
  let position: 'top' | 'bottom' | 'right' | 'left' | undefined
  switch (sidebarView) {
    case 'LOCATION_VIEW':
      position = 'bottom'
      break
    case 'MOBILEMENU_VIEW':
      position = 'left'
      break
    default:
      position = 'right'
  }
  return (
    <Sidebar onClose={closeSidebar} position={position}>
      {sidebarView === 'MOBILEMENU_VIEW' && (
        <MenuSidebarView navItems={navItems} extraNavItems={extraNavItems} />
      )}
      {sidebarView === 'CART_VIEW' && <CartSidebarView />}
      {sidebarView === 'LOCATION_VIEW' && <LocationView />}
    </Sidebar>
  )
}

const SidebarUI: FC<{ navItems?: []; extraNavItems?: [] }> = ({
  navItems,
  extraNavItems,
}) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()
  return displaySidebar ? (
    <SidebarView
      sidebarView={sidebarView}
      closeSidebar={closeSidebar}
      navItems={navItems}
      extraNavItems={extraNavItems}
    />
  ) : null
}

const Layout: FC<Props> = ({ children, pageProps: { ...pageProps } }) => {
  const checkoutId = getCheckoutId()
  const { mutate } = useSWRConfig() // broadcase to revalidate
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  const { setMapScriptReady } = useUI()

  const { data, error } = useSWR(
    checkoutId ? '/api/cart' : null,
    fetcher,
    swrOptions
  )
  // ceate cart required manage orders permission and which
  // exposes secure data that's why we are using backend proxy
  const createCheckout = useCallback(async () => {
    const { data } = await fetch('/api/cart', {
      method: 'POST',
    })
      .then((response) => response.json())
      .catch((error) => {
        // TODO: report error to bug tracker
        console.log('create cart ', error)
      })
  
    if (data?.createCart?.id) {
      setCheckoutId(data?.createCart?.id)
      mutate('/api/cart')
    }
  }, [mutate])

  useEffect(() => {
    // TODO: validate cart and create newone
    //  (checkoutId && !error && data && !data?.cart?.id)

    if (
      !checkoutId ||
      (checkoutId && !error && data && !data?.cart) ||
      (checkoutId && !error && data && data.cart.cartState !== 'Active')
    ) {
      createCheckout()
    }
  }, [checkoutId, createCheckout, data, error])

  return (
    <div className={cn(s.root)}>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={setMapScriptReady}
      />
      <AnnouncementBar bannerData={pageProps.announcementBar} />
      <Navbar />
      <Navigation navItems={pageProps?.navigationData?.body} />
      <main className="fit">{children}</main>
      <Footer pages={pageProps?.footerData?.body} />
      <ModalUI />
      <SidebarUI
        navItems={pageProps?.navigationData?.body}
        extraNavItems={pageProps?.navigationData?.body1}
      />
      <FeatureBar
        title="This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy."
        hide={acceptedCookies}
        action={
          <Button className="mx-5" onClick={() => onAcceptCookies()}>
            Accept cookies
          </Button>
        }
      />
      <FeatureBar
        title="Preview mode is on."
        hide={!pageProps.preview}
        action={<Button onClick={exitPreviewMode}>Exit Preview</Button>}
        key="prismic-preview-mode"
      />
    </div>
  )
}

export default Layout
