const ArrowUp = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 6 6 1 1 6"
        stroke={props.stroke || '#242424'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowUp
