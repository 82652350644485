import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import { FC, useEffect, useRef } from 'react'
import s from './Sidebar.module.css'

interface SidebarProps {
  children: any
  onClose: () => void
  position?: 'right' | 'left' | 'bottom' | 'top'
}

const Sidebar: FC<SidebarProps> = ({
  children,
  onClose,
  position = 'right',
}) => {
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <div
      className={cn(s.root)}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      tabIndex={1}
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className={s.backdrop} onClick={onClose} />
        <section
          className={cn(
            'absolute w-full md:w-auto max-w-full flex outline-none',
            {
              'inset-y-0 right-0': position === 'right',
              'inset-b-0 bottom-0': position === 'bottom',
              'inset-y-0 left-0': position === 'left',
              'inset-t-0 top-0': position === 'top',
            }
          )}
        >
          <div
            className={cn('h-full w-full md:w-screen', {
              'md:max-w-md': position !== 'bottom',
            })}
          >
            <div className={s.sidebar} ref={contentRef}>
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Sidebar
