import { Minus, Plus } from '@components/icons'
import React, { FC } from 'react'
export interface QuantityProps {
  value: number
  increase: () => any
  decrease: () => any
  handleRemove: React.MouseEventHandler<HTMLButtonElement>
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  max?: number
}

const Quantity: FC<QuantityProps> = ({
  value,
  increase,
  decrease,
  handleChange,
  handleRemove,
  max = 1000,
}) => {
  return (
    <div className="flex justify-between px-2 body-2 h-7 quantity border border-greyBg">
      <button
        className="focus:outline-none"
        type="button"
        onClick={value === 1 ? handleRemove : decrease}
        // style={{ marginLeft: '-5px' }}
      >
        <Minus width={16} height={16} />
      </button>

      <input
        className="w-8"
        onChange={(e) =>
          Number(e.target.value) < max + 1 ? handleChange(e) : () => {}
        }
        value={value}
        type="number"
        max={max}
        min="0"
        readOnly
      />

      <button
        className="focus:outline-none"
        type="button"
        onClick={increase}
        style={{ marginLeft: '-25px' }}
        disabled={value < 1 || value >= max}
      >
        <Plus width={16} height={16} />
      </button>
      <style jsx>
        {`
          .quantity {
            width: 80px;
          }
        `}
      </style>
    </div>
  )
}

export default Quantity
