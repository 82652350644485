import {
  Facebook,
  Instagram,
  Logo,
  MasterCard,
  VisaCard,
} from '@components/icons'
import Link from 'next/link'
import { FC } from 'react'

const FooterRoot: FC = () => {
  return (
    <div className="pt-10 md:pt-19">
      <div className="flex justify-between pb-6 border-b border-white md:block">
        <Link href="/" passHref>
          <a>
            <Logo />
          </a>
        </Link>

        <div className="flex gap-2.5 lg:hidden">
          <Facebook />
          <Instagram />
        </div>
      </div>

      <div className=" pt-6 flex justify-between flex-col md:flex-row gap-6 md:gap-0">
        <div className="hidden text-body3 font-roboto text-white lg:block">
          © All rights reserved Cleanaway
        </div>

        <div className="flex gap-2">
          <MasterCard />
          <VisaCard />
          {/* <Image src="/icons/Amex.svg" alt="Amex card" width={40} height={20} /> */}
        </div>
        <div className=" flex gap-5">
          <Link href="/sitemap" passHref>
            <a className="body-3 text-white no-underline hover:text-white">
              Sitemap
            </a>
          </Link>
          <Link href="/privacy-policy" passHref>
            <a className="body-3 text-white no-underline hover:text-white">
              Privacy policy
            </a>
          </Link>
          <Link href="/terms-of-use" passHref>
            <a className="body-3 font-roboto text-white no-underline hover:text-white">
              Terms of use
            </a>
          </Link>
        </div>
        <div className="block text-body3 font-roboto text-white lg:hidden">
          © All rights reserved Cleanaway
        </div>
      </div>
    </div>
  )
}

export default FooterRoot
