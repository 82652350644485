import { ChevronLeft } from '@components/icons'
import cn from 'classnames'
import React, { FC } from 'react'
import s from './SidebarLayout.module.css'

type ComponentProps = {
  className?: string
  heading?: string
  subHeading?: string
} & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
)

const SidebarLayout: FC<ComponentProps> = ({
  children,
  className,
  handleClose,
  handleBack,
  heading,
  subHeading,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <header className={s.header}>
        {handleClose && (
          <div className="flex gap-2 relative w-full">
            {heading && (
              <h6 className="heading-mobile-5 lg:heading-6 text-primary">
                {heading}
              </h6>
            )}
            {subHeading && (
              <div className="body-2 text-primary">{subHeading}</div>
            )}
            <div className="absolute right-0 top-0">
              <button
                onClick={handleClose}
                aria-label="Close"
                className="transition ease-in-out duration-150 flex items-center focus:outline-none"
              >
                <span className="text-link-2 text-charcoal underline">
                  Close
                </span>
              </button>
            </div>
          </div>
        )}
        {handleBack && (
          <button
            onClick={handleBack}
            aria-label="Go back"
            className="hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none"
          >
            <ChevronLeft className="h-6 w-6 hover:text-accent-3" />
            <span className="ml-2 text-accent-7 text-xs">Back</span>
          </button>
        )}
      </header>
      <div className={s.container}>{children}</div>
    </div>
  )
}

export default SidebarLayout
