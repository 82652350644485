const Facebook = ({ ...props }) => {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5 29.24c2.136-.101 4.027-.59 5.586-2.154 1.559-1.56 2.047-3.452 2.154-5.588.125-2.201.125-8.795 0-10.996-.101-2.136-.59-4.029-2.154-5.588-1.559-1.559-3.45-2.046-5.586-2.154-2.201-.125-8.799-.125-11 0-2.13.102-4.022.59-5.586 2.149-1.565 1.559-2.047 3.45-2.154 5.587-.125 2.202-.125 8.8 0 11.002.101 2.136.59 4.029 2.154 5.588 1.564 1.559 3.45 2.047 5.586 2.154 2.201.125 8.799.125 11 0Zm-5.497-2.31c-1.94 0-6.11.156-7.859-.535A4.5 4.5 0 0 1 5.61 23.86c-.696-1.755-.535-5.92-.535-7.86 0-1.94-.155-6.111.535-7.86a4.5 4.5 0 0 1 2.534-2.535c1.755-.697 5.92-.536 7.859-.536 1.94 0 6.11-.155 7.859.536a4.5 4.5 0 0 1 2.534 2.535c.696 1.755.535 5.92.535 7.86 0 1.94.161 6.111-.535 7.86a4.5 4.5 0 0 1-2.534 2.535c-1.755.697-5.92.536-7.86.536Zm7.115-16.452a1.595 1.595 0 1 0 0-3.19 1.595 1.595 0 0 0 0 3.19Zm-7.115 12.359A6.825 6.825 0 0 0 22.838 16a6.825 6.825 0 0 0-6.835-6.837A6.825 6.825 0 0 0 9.167 16a6.825 6.825 0 0 0 6.836 6.837Zm0-2.392A4.452 4.452 0 0 1 11.559 16a4.449 4.449 0 0 1 4.444-4.445A4.449 4.449 0 0 1 20.447 16a4.452 4.452 0 0 1-4.444 4.445Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Facebook
