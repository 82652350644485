const Phone = ({ ...props }) => {
  const { fill } = props
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.147 5.778c.053.79.186 1.564.4 2.302L6.48 9.147a13.178 13.178 0 0 1-.676-3.37h1.343Zm8.764 10.684a11.33 11.33 0 0 0 2.311.4v1.325a13.711 13.711 0 0 1-3.378-.667l1.067-1.058ZM8 4H4.889C4.4 4 4 4.4 4 4.889 4 13.236 10.764 20 19.111 20c.489 0 .889-.4.889-.889V16.01c0-.489-.4-.889-.889-.889-1.102 0-2.178-.178-3.173-.507a.748.748 0 0 0-.276-.044.91.91 0 0 0-.63.258l-1.956 1.955a13.465 13.465 0 0 1-5.858-5.858L9.173 8.97a.892.892 0 0 0 .223-.907 10.098 10.098 0 0 1-.507-3.173C8.889 4.4 8.489 4 8 4Z"
        fill={fill || '#000'}
      />
    </svg>
  )
}

export default Phone
