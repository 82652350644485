const Hamburger = ({ ...props }) => {
  return (
    <svg
      width={18}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12h18v-2H0v2Zm0-5h18V5H0v2Zm0-7v2h18V0H0Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Hamburger
